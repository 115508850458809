var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",class:{ 'section-invert': _vm.invertColors }},[_c('v-container',{staticClass:"full-height",attrs:{"fluid":_vm.data.configuration.wb_container_fluid__checkbox__}},[_c('v-row',{staticClass:"full-height",attrs:{"justify":"center"}},[_c('v-col',{style:({ minHeight: ((_vm.data.configuration['wb_height__style_height__']) + "px") }),attrs:{"cols":_vm.data.configuration.wb_cols_cols__cols_cols__,"sm":_vm.data.configuration.wb_cols_sm__cols_sm__,"md":_vm.data.configuration.wb_cols_md__cols_md__,"lg":_vm.data.configuration.wb_cols_lg__cols_lg__,"xl":_vm.data.configuration.wb_cols_xl__cols_xl__}},[(!_vm.imageHidden && _vm.imageOnTop && (!_vm.isMobile || _vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__))?_c('v-img',{class:( _obj = {
                  'mr-4': !_vm.isMobile && !_vm.imageOnRightSide && !_vm.imageOnCenter,
                  'ml-2': !_vm.isMobile && _vm.imageOnRightSide && !_vm.imageOnCenter,
                  'mb-4': true,
                  'mx-auto': _vm.imageOnCenter
               }, _obj[_vm.classForImagePosition] = true, _obj[_vm.classForImageRound] = true, _obj ),attrs:{"contain":_vm.data.configuration.wb_image_contain__checkbox__,"src":_vm.getImageUrl('img_1'),"width":_vm.getImageWidth,"height":_vm.getImageHeight}}):_vm._e(),_c('div',{staticClass:"flexible-section-content",class:{
          'mt-2': _vm.isMobile && !_vm.imageOnRightSide && (_vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__),
        },domProps:{"innerHTML":_vm._s(_vm.data.configuration.wb_text__html_text__)}}),(_vm.hasCtaButtons)?_c('div',{staticClass:"style-1-base d-flex flex-wrap mt-4 mb-4",class:( _obj$1 = {
                  'style-2-base': _vm.invertColors
               }, _obj$1[_vm.classForButtonsPosition] = true, _obj$1[_vm.classForImageRound] = true, _obj$1 )},_vm._l((_vm.data.configuration.wb_buttons__array_cta_buttons__),function(button,index){return _c('default-button',{key:index,attrs:{"button":_vm.transformCtaButton(button),"additional-class":"ma-2"}},[_vm._v(" "+_vm._s(button.title)+" ")])}),1):_vm._e(),(!_vm.imageHidden && !_vm.imageOnTop && (!_vm.isMobile || _vm.isMobile && !_vm.data.configuration.wb_image_hide_on_mobile__checkbox__))?_c('v-img',{class:( _obj$2 = {
                  'mr-4': !_vm.isMobile && !_vm.imageOnRightSide && !_vm.imageOnCenter,
                  'ml-2': !_vm.isMobile && _vm.imageOnRightSide && !_vm.imageOnCenter,
                  'mb-4': true,
                  'mx-auto': _vm.imageOnCenter
               }, _obj$2[_vm.classForImagePosition] = true, _obj$2 ),attrs:{"contain":_vm.data.configuration.wb_image_contain__checkbox__,"src":_vm.getImageUrl('img_1'),"width":_vm.getImageWidth,"height":_vm.getImageHeight}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }